import React from "react";
import useTranslations from "../../../../components/useTranslations";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";

const Haccp = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { haccp } = useTranslations();

	return (
		<>
			<article className="two-column-left-nav">
				<SupportLeftNav />
				<section className="main-content">
					<h2 className={"large bold"}>{haccp}</h2>
					<div className="content-container  catchall">
						<h2>
						HACCP支援製品特集
						</h2>
						<p className="date">2024年9月27日作成</p>
						<p>
  						HACCP（Hazard Analysis and Critical Control Point）とは、製品の原材料受入から 最終製品出荷までの各工程において製品の安全を確保するための衛生管理の手法です。
                        <br/>
                        HACCPでは、製造の全工程において「危害の予測」「監視」「記録」することが重要なポイントといえます。
						<br/><br/>
                        2021年6月の「HACCP義務化」を受け、すべての食品等事業者は衛生管理手法を取り入れなければなりません。
						<br/><br/>
						「食」の安全管理の方法として重要な HACCP (ハサップ) 。 
						<br/>
                        HACCP導入時に最適な測定機器をご紹介します。<br/><br/>
						</p>
						<p>
						製品名をクリックすると、各製品の詳細ページを表示します。
						<br/>
						また、製品をまとめたパンフレットは
						<a className="link" href="https://static.sksato.co.jp/leaflet/ja/shyokuhin2024.pdf" target={"_blank"} rel="noreferrer">
						こちら
						</a>
						をご覧ください。<br/><br/>
						</p>

						<div className="checklist">
						    <h6>＜輸送・入荷（受入検査）＞</h6>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/8269-00" target={"_blank"} rel="noreferrer">	
		    					      No.8269-00　防水放射温度計 SK-8950       							
									  </a>
									  <p>
  	         							サークルタイプのレーザマーカで測定箇所の目安が容易な防塵、防水構造の放射温度計
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/8269-00_M01.png"
										height="300"
										width="300"
										alt="SK-8950"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/8264-00" target={"_blank"} rel="noreferrer">	
		    					      No.8269-00　食品用放射温度計 SK-8920       							
									  </a>
									  <p>
									  食品ごとの最適な放射率をマークで選択可能な食品向け放射温度計
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/8264-00_M01.png"
										height="300"
										width="300"
										alt="SK-8920"
									/>								
								</div>
							</div>
							<div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/8800-00" target={"_blank"} rel="noreferrer">	
		    					      No.8800-00　2ch温度ロガー記憶計　SK-L751<br/>
									  </a>
									  <p>
									  最大16000データを記憶できる温度2chロガー
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/8800-00_X01.png"
										height="300"
										width="300"
										alt="SK-L751"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
   								    </p>
								</div>
							</div>
					    </div>

						<div className="checklist">
						<h6>＜保管（冷蔵庫・倉庫・環境管理）＞</h6>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1830-00" target={"_blank"} rel="noreferrer">	
		    					      No.1830-00　冷蔵庫用温度計 PC-3310	
									  </a>
									  <p>
									    測定温度・最高・最低温度を小数点0.1まで常時表示
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1830-00_M01.jpg"
										height="300"
										width="300"
										alt="PC-3310"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/8440-20" target={"_blank"} rel="noreferrer">	
		    					      No.8440-20　防水型無線温度ロガー　SK-320BT-C
									  </a>
									  <p>
									  Bluetooth機能が付いたスマートフォンなどの携帯端末と無線で通信をおこなう、防水性能を備えた温度データロガー
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/8440-20_M01.jpg"
										height="300"
										width="300"
										alt="SK-320BT-C"
									/>								
								</div>
						    </div>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1717-00" target={"_blank"} rel="noreferrer">	
		    					      No.1717-00　冷蔵庫用温度計チェッカーメイトⅡ　2針
									  </a>
									  <p>
									  ドアを開けずに冷蔵庫内の温度を２ヶ所同時測定
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1717-00_M01.jpg"
										height="300"
										width="300"
										alt="チェッカーメイト"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1713-00" target={"_blank"} rel="noreferrer"> 	
		    					      No.1713-00　冷蔵庫用温度計　ポリス
									  </a>
									  <p>
									  適温が一目で判る冷凍冷蔵庫用温度計
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1713-00_M01.jpg"
										height="300"
										width="300"
										alt="ポリス"
									/>								
								</div>
						    </div>
						    <div className="flex-container">								
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/8850-00" target={"_blank"} rel="noreferrer">	
		    					      No.8850-00　2ch温度ロガー（PT）記憶計 SK-L753
									  </a>
									  <p>
									  -99.9℃までの極低温を精度よく測定できるデータロガー
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/8850-00_X01.png"
										height="300"
										width="300"
										alt="SK-L753"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
   								    </p>
								</div>
							</div>
						</div>
					    
					    <div className="checklist">
						<h6>＜調理（手洗い・タイマー）＞</h6>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1707-20" target={"_blank"} rel="noreferrer">	
		    					      No.1707-20　ノータッチタイマー手洗い当番　TM-27
									  </a>
									  <p>
									  手をかざすだけ（非接触）でカウントダウンを開始する手洗い専用タイマー
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1707-20_M01.png"
										height="300"
										width="300"
										alt="TM-27"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1707-30" target={"_blank"} rel="noreferrer">	
		    					      No.1707-30　ノータッチタイマー　TM-29
									  </a>
									  <p>
									  手をかざすだけ（非接触）でカウントダウンを開始する、防滴タイプのタイマー
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1707-30_M01.jpg"
										height="300"
										width="300"
										alt="TM-29"
									/>								
							    </div>
						    </div>
						</div>

						<div className="checklist">
						<h6>＜調理（加熱温度管理・冷却温度管理・調合）＞</h6>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/8078-00" target={"_blank"} rel="noreferrer">	
		    					      No.8078-00　防水型デジタル温度計 SK-270WP（標準センサ付）
									  </a>
									  <p>
									  測定対象物が設定温度を超えると自動でタイマーをスタートさせるHACCP機能付<br/>
									  流水での丸洗い（センサ接続時）、アルコールでの拭き取り清掃が可能
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/8078-00_M01.png"
										height="300"
										width="300"
										alt="SK-270WP"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/8078-60" target={"_blank"} rel="noreferrer">	
		    					      No.8078-60　防水型無線温度計 SK-270WP-B （標準センサ付）
									  </a>
									  <p>
									  Bluetooth通信で測定値、または本体に記録済みのデータをパソコンへ無線で送信
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/8078-60_M01.jpg"
										height="300"
										width="300"
										alt="SK-270WP-B"
									/>								
								</div>
						    </div>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1756-00" target={"_blank"} rel="noreferrer">	
		    					      No.1756-00　防水型デジタル温度計　PC-9230
									  </a>
									  <p>
									  水がかかる場所でも使用可能な、高速応答タイプの防水型デジタル温度計
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1756-00_M01.jpg"
										height="300"
										width="300"
										alt="PC-9230"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1758-00" target={"_blank"} rel="noreferrer">	
		    					      No.1758-00　防水型デジタル温度計　PC-9235
									  </a>
									  <p>
									  水がかかる場所でも使用可能な、高速応答タイプ、センサ長30cmの防水型デジタル温度計
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1758-00_M01.jpg"
										height="300"
										width="300"
										alt="ポリス"
									/>								
								</div>
						    </div>
						    <div className="flex-container">								
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/2350-00" target={"_blank"} rel="noreferrer">	
		    					      No.2350-00　寸胴鍋用バイメタル温度計 BM-R-75
									  </a>
									  <p>
									  表示面が45度傾斜しているため温度確認が容易な寸胴鍋用温度計（鍋取付用クリップ付）
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/2350-00_M01.png"
										height="300"
										width="300"
										alt="BM-R-75"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/1726-20" target={"_blank"} rel="noreferrer">	
		    					      No.1726-20　オーブンメータ
									  </a>
									  <p>
									  オーブンの中に入れ、窓から温度を確認できるオーブン料理専用の温度計　
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/1726-20_M01.jpg"
										height="300"
										width="300"
										alt="オーブンメータ"
									/>								
								</div>
							</div>
						    <div className="flex-container">								
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/0180-00" target={"_blank"} rel="noreferrer">	
									  No.0180-00　手持屈折計 SK-100R 自動温度補正付
									  </a>
									  <p>
									  見やすい目盛を採用した液体濃度を測定する屈折計
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/0180-00_M01.jpg"
										height="300"
										width="300"
										alt="SK-100R"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
   								    </p>
								</div>
							</div>
						</div>

					    <div className="checklist">
						<h6>＜環境管理・温度計基準器）＞</h6>
						    <div className="flex-container">
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/8870-00" target={"_blank"} rel="noreferrer">	
		    					      No.8870-00　温湿度ロガー記憶計　SK-L754
									  </a>
									  <p>
									  最大16000データを記憶できる温湿度ロガー
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/8870-00_X01.png"
										height="300"
										width="300"
										alt="SK-L754"
									/>								
								</div>
								<div className="temp01_flex1 temp01_image">
									<p className="medium temp01_figure_explanation">
									  <a className="link" href="https://www.sksato.co.jp/8012-00" target={"_blank"} rel="noreferrer">	
		    					      No.8012-00　精密型デジタル温度計　SK-810PT
									  </a>
									  <p>
									  従来の水銀ガラス標準温度計に代わる-100℃から360℃まで（分解能：0.01℃）を高精度に測定するデジタル温度計
    								  </p>
									</p>
									<img
										className="temp01_chart"
										src="https://static.sksato.co.jp/product/ja/8012-00_X01.png"
										height="300"
										width="300"
										alt="SK-810PT"
									/>								
							    </div>
						    </div>
						</div>

					</div>
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Haccp;
